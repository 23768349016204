import React, {Fragment, useState} from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CartItem from './CartItem'
import Paper from '@material-ui/core/Paper'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Link } from 'gatsby'
import {formatCurrencyString, useShoppingCart} from '../../context'

export default function Cart () {
  const [loading] = useState(false)
  /* Gets the totalPrice and a method for redirecting to stripe */
  const { totalPrice, cartCount, cartDetails } = useShoppingCart()

  /*
  const handleClick = async () => {
    setLoading(true)
    // redirectToCheckout()
    // Call your backend to create the Checkout Session
    const response = await fetch(`${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/create-checkout-session`, { method: 'POST' })

    const session = await response.json()

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise
    const result = await stripe.redirectToCheckout({
      sessionId: session.id
    })

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
      setLoading(false)
    }
  }
  */

  return (
    <div>
      <Box style={{padding: '1rem', width: '100%'}}>
        <Container maxWidth={'md'}>
          <Grid container>
            <Grid item sm={4} align={'left'}>
              <Link to='/'>
                <Box alignItems='center' display='flex'>
                  <ArrowBackIcon style={{marginRight: 10}} />
                  <Typography>
                    Continue shopping
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item sm={4} align={'center'}>
              <Typography variant={'h5'}>
                Cart Summary
              </Typography>
            </Grid>
            <Grid item sm={4} align={'right'} />
          </Grid>
        </Container>
      </Box>
      <Box marginBottom={5} style={{padding: '1rem'}}>
        <Container maxWidth={'md'}>
          <Paper>
            <Grid container>
              <Grid item xs={12}>
                {cartCount > 0 ? (
                  <Fragment>
                    {Object.entries(cartDetails).map(([key, item], index) => (
                      <CartItem
                        key={index}
                        title={item.name}
                        image={item.image}
                        cost={item.price}
                        quantity={item.quantity}
                        metadata={item.metadata}
                        sku={item.sku}
                      />
                    ))}
                    <Box style={{padding: 16}}>
                      <Grid container>
                        <Grid item xs={8} />
                        <Grid item xs={4}>
                          {/* This is where we'll render our cart */}
                          <Typography variant={'h6'} align={'right'}>Total: {formatCurrencyString({
                            value: totalPrice,
                            currency: 'USD',
                            language: 'en-US'
                          })}
                          </Typography>
                          <Typography variant={'body2'} align={'right'} gutterBottom>Shipping, and taxes will be calculated at checkout.</Typography>
                          {/* Redirects the user to Stripe */}
                          <Button variant={'contained'} color={'primary'} fullWidth disabled={loading} href='/checkout/contactInfo'>
                            {loading ? 'Loading...' : 'Checkout'}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Fragment>
                ) : (
                  <Box margin={5}>
                    <Typography>Your cart is empty</Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </div>
  )
}
