import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import SvgIcon from '@material-ui/core/SvgIcon'
import {formatCurrencyString, useShoppingCart} from '../../context'

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #eee',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    textAlign: 'left'
  },
  details: {
    display: 'flex',
    width: '180px',
    '& div': {
      flex: '1 1 50%'
    }
  },
  quantity: {
    padding: '0 1rem'
  }
}))

function TrashIcon(props) {
  return (
      <SvgIcon {...props} viewBox='0 0 48 48'>
        <path
            xmlns='http://www.w3.org/2000/svg'
            d='M 24 4 C 20.491685 4 17.570396 6.6214322 17.080078 10 L 10.238281 10 A 1.50015 1.50015 0 0 0 9.9804688 9.9785156 A 1.50015 1.50015 0 0 0 9.7578125 10 L 6.5 10 A 1.50015 1.50015 0 1 0 6.5 13 L 8.6386719 13 L 11.15625 39.029297 C 11.427329 41.835926 13.811782 44 16.630859 44 L 31.367188 44 C 34.186411 44 36.570826 41.836168 36.841797 39.029297 L 39.361328 13 L 41.5 13 A 1.50015 1.50015 0 1 0 41.5 10 L 38.244141 10 A 1.50015 1.50015 0 0 0 37.763672 10 L 30.919922 10 C 30.429604 6.6214322 27.508315 4 24 4 z M 24 7 C 25.879156 7 27.420767 8.2681608 27.861328 10 L 20.138672 10 C 20.579233 8.2681608 22.120844 7 24 7 z M 11.650391 13 L 36.347656 13 L 33.855469 38.740234 C 33.730439 40.035363 32.667963 41 31.367188 41 L 16.630859 41 C 15.331937 41 14.267499 40.033606 14.142578 38.740234 L 11.650391 13 z M 20.476562 17.978516 A 1.50015 1.50015 0 0 0 19 19.5 L 19 34.5 A 1.50015 1.50015 0 1 0 22 34.5 L 22 19.5 A 1.50015 1.50015 0 0 0 20.476562 17.978516 z M 27.476562 17.978516 A 1.50015 1.50015 0 0 0 26 19.5 L 26 34.5 A 1.50015 1.50015 0 1 0 29 34.5 L 29 19.5 A 1.50015 1.50015 0 0 0 27.476562 17.978516 z'
        />
      </SvgIcon>
  )
}

export default function CartItem ({ title, image, cost, quantity, metadata, sku }) {
  const classes = useStyles()
  const { removeItem } = useShoppingCart()

  return (
    <div className={classes.root}>
      <Grid container alignItems={'center'} justify={'center'}>
        <Grid item sm={2}>
          <img src={image} width={'100%'} alt={title} />
        </Grid>
        <Grid item sm={5}>
          <Typography variant={'h6'} sm={5} justify={'center'}>{title}</Typography>
          {metadata && Object.entries(metadata).map(([key, item], index) => (
              key !== 'files' && key !== 'parcel' && (
                  <Box key={index} alignItems={'center'} display={'flex'}>
                    <Typography style={{marginRight: 10}}>{key}:</Typography>
                    <Typography>{item}</Typography>
                  </Box>
              )
          ))}
        </Grid>
        <Grid item sm={2}>
          <Typography align={'right'}>Qty: {quantity}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography align={'right'}>
            {formatCurrencyString({
              value: quantity * cost,
              currency: 'USD',
              language: navigator.language
            })}
          </Typography>
        </Grid>
        <Grid item sm={1} style={{textAlign: 'center'}}>
          <IconButton aria-label="remove" onClick={() => removeItem(sku)}>
            <TrashIcon style={{color: '#DE1E35'}} />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}
