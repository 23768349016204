import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layouts'
import HeaderNavbar from '../components/HeaderNavbar'
import Cart from '../components/Cart/Cart'

export default function CartOverview () {
  return (
    <Layout>
      <div className='pageContent'>
        <Helmet>
          <title>Cart | Signxperts</title>
        </Helmet>
          <HeaderNavbar/>
        <Cart/>
      </div>
    </Layout>
  )
}
